
import { Component, Vue } from 'vue-property-decorator'
import { SoilHumidityDetail, EchartList, TypeList } from '../../types/soilHumidity.d'
import echarts from 'echarts'

@Component({
  name: 'History'
})
export default class History extends Vue {
  private searchInfo: { dateRange: Array<string> } = {
    dateRange: []
  }

  private selectTime = 0
  private pickerOptions = {
    // 选中日期后会执行的回调
    onPick: (date: {maxDate: any; minDate: any}) => {
      this.selectTime = date.minDate.getTime()
      if (date.maxDate) {
        this.selectTime = 0
      }
    },
    disabledDate: (time: any) => {
      if (this.selectTime !== 0) {
        const dayTime = 29 * 24 * 3600 * 1000
        const minTime = this.selectTime - dayTime
        const maxTime = this.selectTime + dayTime
        return (time.getTime() < minTime || time.getTime() > maxTime)
      }
    }
  }

  private loading = false
  private dataList: Array<SoilHumidityDetail> = []
  page = 1
  size = 10
  total = 0
  private nums = ''
  private topHeight = 1

  get id () {
    return this.$route.params.id as string
  }

  get type () {
    return this.$route.params.type as string
  }

  private echart: any
  private echartSelect = '0'
  private monitorList: Array<string> = []
  private echartData: EchartList = {
    ph: [],
    humidity: [],
    conductivity: [],
    temperature: [],
    deviceNumber: '',
    collectTime: [],
    nitrogen: [],
    phosphorus: [],
    potassium: [],
    redox: []
  }

  private typeList: Array<TypeList> = []

  $refs!: {
    echart: any;
  }

  created () {
    this.initTime()
    if (this.type === '1') {
      this.getDataChart()
      this.getData()
    } else if (this.type === '2') {
      this.getGroupChart()
    }
  }

  mounted () {
    window.addEventListener('resize', this.resize)
  }

  destroyed () {
    window.removeEventListener('resize', this.resize)
  }

  initTime () {
    const date = new Date()
    const val = date.getFullYear() + '-' + this.addZero(date.getMonth() + 1) + '-' + this.addZero(date.getDate())
    this.searchInfo.dateRange = [val, val]
  }

  addZero (val: number) {
    return val < 10 ? '0' + val : String(val)
  }

  // 单设备折线图
  getDataChart (): void {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.$axios.get(this.$apis.soilHumidity.selectSoilWithMap, {
        deviceId: this.id,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1]
      }).then((res) => {
        this.echartData.deviceNumber = res.deviceNumber || ''
        this.echartData.temperature = res.temperature || []
        this.echartData.conductivity = res.conductivity || []
        this.echartData.humidity = res.humidity || []
        this.echartData.ph = res.ph || []
        this.echartData.collectTime = res.collectTime || []
        this.echartData.nitrogen = res.nitrogen || []
        this.echartData.phosphorus = res.phosphorus || []
        this.echartData.potassium = res.potassium || []
        this.echartData.redox = res.redox || []
        this.getDetail()
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  // 分组折线图
  getGroupChart () {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.$axios.get(this.$apis.soilHumidity.selectSoilWithMaps, {
        groupId: this.id,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1]
      }).then((res) => {
        this.echartData.collectTime = res[0].soilWithMap.collectTime
        this.getTypeList(res)
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  // 处理分组数据
  getTypeList (data: any) {
    if (this.id) {
      this.$axios.get(this.$apis.soilHumidity.selectSoilWithConfig, {
        groupId: this.id
      }).then(res => {
        this.nums = res.groupName || ''
        if (res && res.types) {
          res.types.forEach((item: any) => {
            this.monitorList.push(item.isSelect)
          })
        } else {
          this.monitorList = ['0', '0', '0', '0', '1', '1', '1', '1']
        }
        const legend = data.map((item: any) => {
          const txt = item.depth || '--'
          return item.deviceNumber + '（' + txt + 'cm）'
        })
        const colorList = ['rgba(71, 162, 255, ', 'rgba(89, 203, 116, ', 'rgba(251, 212, 76, ', 'rgba(83, 200, 209, ', 'rgba(243, 101, 125, ', 'rgba(152, 96, 229, ', 'rgba(68, 82, 137, ', 'rgba(235, 166, 117, ', 'rgba(115, 185, 136, ', 'rgba(84, 82, 210, ']
        this.typeList = [
          {
            legendData: legend,
            color: colorList,
            data: this.getGroupData('temperature', data),
            unit: '℃',
            value: '0',
            label: '土壤温度曲线图'
          },
          {
            legendData: legend,
            color: colorList,
            data: this.getGroupData('humidity', data),
            unit: '%',
            value: '1',
            label: '土壤湿度曲线图'
          },
          {
            legendData: legend,
            color: colorList,
            data: this.getGroupData('ph', data),
            unit: '',
            value: '2',
            label: 'pH值曲线图'
          },
          {
            legendData: legend,
            color: colorList,
            data: this.getGroupData('conductivity', data),
            unit: 'mS/cm',
            value: '3',
            label: 'EC值曲线图'
          },
          {
            legendData: legend,
            color: colorList,
            data: this.getGroupData('nitrogen', data),
            unit: 'mg/kg',
            value: '4',
            label: '氮含量曲线图'
          },
          {
            legendData: legend,
            color: colorList,
            data: this.getGroupData('phosphorus', data),
            unit: 'mg/kg',
            value: '5',
            label: '磷含量曲线图'
          },
          {
            legendData: legend,
            color: colorList,
            data: this.getGroupData('potassium', data),
            unit: 'mg/kg',
            value: '6',
            label: '钾含量曲线图'
          },
          {
            legendData: legend,
            color: colorList,
            data: this.getGroupData('redox', data),
            unit: 'mV',
            value: '7',
            label: 'ORP含量曲线图'
          }
        ]
        this.draw(Number(this.echartSelect))
      })
    }
  }

  getGroupData (str: string, data: any) {
    const list = data.map((item: any) => {
      return item.soilWithMap[str]
    })
    this.topHeight = list.length
    return list
  }

  getData (): void {
    if (this.searchInfo.dateRange && this.searchInfo.dateRange[0]) {
      this.loading = true
      this.$axios.get(this.$apis.soilHumidity.getReportData, {
        deviceId: this.id,
        startTime: this.searchInfo.dateRange[0],
        endTime: this.searchInfo.dateRange[1],
        page: this.page,
        size: this.size
      }).then((res: SoilHumidityDetail) => {
        this.total = res.total || 0
        this.dataList = res.list || []
      }).finally(() => {
        this.loading = false
      })
    } else {
      this.$message.error('请选择查询日期范围')
    }
  }

  onSearch () {
    this.page = 1
    if (this.type === '1') {
      this.getDataChart()
      this.getData()
    } else if (this.type === '2') {
      this.getGroupChart()
    }
  }

  // 类型筛选
  getDetail () {
    if (this.id) {
      this.$axios.get(this.$apis.devices.getSoilConfig, {
        deviceId: this.id
      }).then(res => {
        if (res !== null && res.types) {
          res.types.forEach((item: any) => {
            this.monitorList.push(item.isSelect)
          })
        } else {
          this.monitorList = ['0', '0', '0', '0', '1', '1', '1', '1']
        }
        this.typeList = [
          {
            legendData: ['土壤温度'],
            color: ['rgba(255, 193, 23, '],
            data: [this.echartData.temperature],
            unit: '℃',
            value: '0',
            label: '土壤温度曲线图'
          },
          {
            legendData: ['土壤湿度'],
            color: ['rgba(44, 142, 255, '],
            data: [this.echartData.humidity],
            unit: '℃',
            value: '1',
            label: '土壤湿度曲线图'
          },
          {
            legendData: ['pH'],
            color: ['rgba(18, 194, 194, '],
            data: [this.echartData.ph],
            unit: '',
            value: '2',
            label: 'pH值曲线图'
          },
          {
            legendData: ['EC'],
            color: ['rgba(47, 194, 90, '],
            data: [this.echartData.conductivity],
            unit: 'mS/cm',
            value: '3',
            label: 'EC值曲线图'
          },
          {
            legendData: ['氮'],
            color: ['rgba(255, 193, 23, '],
            data: [this.echartData.nitrogen],
            unit: 'mg/kg',
            value: '4',
            label: '氮含量曲线图'
          },
          {
            legendData: ['磷'],
            color: ['rgba(44, 142, 255, '],
            data: [this.echartData.phosphorus],
            unit: 'mg/kg',
            value: '5',
            label: '磷含量曲线图'
          },
          {
            legendData: ['钾'],
            color: ['rgba(109, 212, 0, '],
            data: [this.echartData.potassium],
            unit: 'mg/kg',
            value: '6',
            label: '钾含量曲线图'
          },
          {
            legendData: ['ORP'],
            color: ['rgba(47, 194, 90, '],
            data: [this.echartData.redox],
            unit: 'mV',
            value: '7',
            label: 'ORP含量曲线图'
          }
        ]
        this.draw(Number(this.echartSelect))
      })
    }
  }

  // 折线图
  draw (type: number) {
    const colorList = ['rgba(71, 162, 255, 1)', 'rgba(89, 203, 116, 1)', 'rgba(251, 212, 76, 1)', 'rgba(83, 200, 209, 1)', 'rgba(243, 101, 125, 1)', 'rgba(152, 96, 229, 1)', 'rgba(68, 82, 137, 1)', 'rgba(235, 166, 117, 1)', 'rgba(115, 185, 136, 1)', 'rgba(84, 82, 210, 1)']
    this.$nextTick(() => {
      const series: any = []
      const params = this.typeList[type]
      const tops = 50 + Math.ceil(this.topHeight / 7) * 50
      params.legendData.forEach((item: string, index: number) => {
        series.push({
          name: item,
          type: 'line',
          symbolSize: 10,
          yAxisIndex: 0,
          smooth: true,
          lineStyle: {
            width: 4,
            shadowBlur: 0,
            shadowColor: `${params.color[index]}0.2)`,
            shadowOffsetY: 20
          },
          itemStyle: {
            normal: {
              borderWidth: 4,
              borderColor: `${params.color[index]}1)`
            }
          },
          data: params.data[index]
        })
        this.echart = echarts.init(this.$refs.echart)
        const option: any = {
          tooltip: {
            trigger: 'axis',
            confine: true,
            textStyle: {
              fontSize: 12
            }
          },
          grid: {
            top: tops,
            left: 10,
            right: 10,
            bottom: 10,
            containLabel: true
          },
          backgroundColor: 'transparent',
          legend: {
            icon: 'rect',
            itemWidth: 12,
            itemHeight: 1,
            orient: 'horizontal',
            align: 'left',
            top: 35,
            left: 0,
            itemGap: 30,
            textStyle: {
              fontSize: 12,
              fontWeight: 400,
              color: '#8c8c8c',
              lineHeight: 14
            },
            data: params.legendData
          },
          color: colorList,
          xAxis: {
            type: 'category',
            axisTick: {
              alignWithLabel: {
                boundaryGap: true
              }
            },
            axisLine: {
              lineStyle: {
                color: 'rgba(0, 0, 0, 0.45)'
              }
            },
            axisLabel: {
              show: true,
              margin: 15,
              textStyle: {
                color: 'rgba(0, 0, 0, 0.45)'
              }
            },
            data: this.echartData.collectTime
          },
          yAxis: [{
            interval: 5,
            type: 'value',
            axisLabel: {
              formatter: `{value}${params.unit}`
            },
            axisLine: {
              show: false,
              lineStyle: {
                color: 'rgba(0, 0, 0, 0.45)'
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(0, 0, 0, 0.15)'
              }
            }
          }],
          series: series
        }
        this.echart.setOption(option, true)
      })
    })
  }

  resize () {
    if (this.echart) {
      this.echart.resize()
    }
  }

  changeEchart (type: string) {
    this.draw(Number(type))
  }
}
